<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <Header />

      <div
        v-show="loading && !showCanvas && userInfoOk"
        class="flex-center mt-3"
      >
        <div class="loading2"></div>
      </div>
      <div class="flex-center" style="flex-direction: column">
        <span class="warning mt-3" v-show="warningLoading">
          {{ t("Esta página não existe mais ou foi removida.") }}
        </span>
        <router-link
          to="/home"
          class="btn desktop3 tablet5 mt-2"
          v-show="warningLoading"
          ><span>{{
            t("Clique aqui para voltar ao início")
          }}</span></router-link
        >
      </div>
      <div v-show="!warningLoading" class="home container">
        <div class="grid">
          <div class="info-holder mt-2-mobile mb-3-tablet">
            <transition enter-active-class="animated fadeInLeft">
              <h1 v-show="!loading && userInfoOk">{{ template.title }}</h1>
            </transition>
            <transition enter-active-class="animated fadeInLeft">
              <textarea
                v-show="!loading && userInfoOk"
                class="textarea-description"
                v-model="template.description"
                disabled
              >
              </textarea>
            </transition>

            <select
              @change="changeStyle"
              class="styles mobile12 desktop-hidden tablet-hidden"
              v-model="selectedStyle"
            >
              <option
                v-for="style in template.templateStyle"
                :key="style.id"
                :value="style.style.id"
              >
                {{ t("Tema") }}: {{ style.style.name }}
              </option>
            </select>

            <transition enter-active-class="animated fadeInLeft">
              <p
                v-show="!loading && template.format == 'carousel' && userInfoOk"
                style="animation-delay: 300ms"
                class="carousel-text-help"
              >
                {{ t("Clique em uma imagem para exibi-la") }}
              </p>
            </transition>
            <transition enter-active-class="animated fadeInUp">
              <div
                v-show="
                  !loading && userInfoOk && template.format !== 'carousel'
                "
                class="btns-holder"
                style="animation-delay: 600ms"
              >
                <div @click="downloadFile" class="btn">
                  <font-awesome-icon
                    v-show="!downloading"
                    :icon="['fas', 'cloud-download-alt']"
                  />
                  <span v-show="!downloading">{{ t("Baixar") }}</span>
                  <div v-show="downloading" class="loading2"></div>
                </div>
                <div @click="copyDescription" class="btn border">
                  <font-awesome-icon :icon="['fas', 'copy']" />
                  <span>{{ t("Copiar legenda") }}</span>
                </div>
              </div>
            </transition>

            <transition enter-active-class="animated fadeInUp">
              <div v-show="!userInfoOk" class="mt-2">
                <h3>{{ t("Complete suas informações") }}</h3>
                <p>
                  {{
                    t(
                      "Para baixar o template, por favor, certifique-se de subir uma foto e informar seu nome profissional / artístico / comercial."
                    )
                  }}
                </p>
                <router-link
                  to="/meu-selo"
                  class="btn border info mt-2 desktop8 tablet8"
                >
                  <span>{{ t("Clique aqui para continuar") }}</span>
                </router-link>
              </div>
            </transition>

            <div
              v-show="!loading && template.format == 'carousel'"
              class="grid-images animated fadeInLeft mt-1"
              style="animation-delay: 500ms"
            >
              <div v-show="loadingItemCarousel" class="loading2 mt-2"></div>
              <div
                v-for="(item, index) in selectedTemplateStyle.templateCarousel"
                :key="index"
                v-show="!loadingItemCarousel"
                class="carousel-item"
                :class="{ stories: template.type === 'stories' }"
                :style="`background-image:url(${item.image})`"
                @click="setTemplateCarousel(item, index)"
              >
                <div
                  class="download"
                  @click="setTemplateCarousel(item, index, true)"
                >
                  Baixar
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="userInfoOk && !loading"
            id="canvasHolder"
            class="template"
          >
            <select
              @change="changeStyle"
              class="styles mobile12 mobile-hidden"
              v-model="selectedStyle"
            >
              <option
                v-for="style in template.templateStyle"
                :key="style.id"
                :value="style.style.id"
              >
                {{ t("Tema") }}: {{ style.style.name }}
              </option>
            </select>
            <video
              style="width: 100%"
              :src="selectedVideo"
              v-if="selectedVideo"
              controls
            />

            <div class="canvas-holder" v-if="!selectedVideo">
              <canvas
                id="templateCanvas"
                :class="{ active: loadingCanvas == true }"
              ></canvas>
            </div>

            <div class="canvas-icons mt-1">
              <div
                v-show="template.format == 'carousel'"
                @click="sliderLeft"
                class="control prev"
              >
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </div>

              <div
                v-show="loadingCanvas && !selectedVideo"
                class="loading2 canvas-loading"
              ></div>

              <div
                v-show="template.format == 'carousel'"
                @click="sliderRight"
                class="control next"
              >
                <font-awesome-icon
                  v-show="!downloading"
                  :icon="['fas', 'chevron-right']"
                />
              </div>
            </div>
          </div>

          <transition enter-active-class="animated zoomIn">
            <img
              class="desktop8 tablet10 mobile12 mt-3"
              style="animation-delay: 600ms"
              v-show="!loading && !userInfoOk"
              :src="template.coverImage"
              :alt="template.description"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import authentication from "@/util/authentication";
import platform from "@/util/platform";
import { fabric } from "fabric";
export default {
  components: {
    Navbar,
    Header,
  },
  data() {
    return {
      loading: true,
      loadingCanvas: true,
      loadingItemCarousel: false,
      template: {},
      canvas: {},
      userInfoOk: true,
      showCanvas: false,
      downloading: false,
      selectedStyle: "",
      selectedTemplateStyle: "",
      selectedVideo: "",
      selectedTemplateCarousel: {},
      warningLoading: false,
    };
  },
  created() {
    this.validateUserInfo();
  },
  methods: {
    sliderRight() {
      if (
        this.selectedTemplateStyle.templateCarousel.length - 1 ==
        this.selectedTemplateCarousel.index
      ) {
        this.setTemplateCarousel(
          this.selectedTemplateStyle.templateCarousel[0],
          0
        );
      } else {
        let index = this.selectedTemplateCarousel.index + 1;
        this.setTemplateCarousel(
          this.selectedTemplateStyle.templateCarousel[index],
          index
        );
      }
    },
    sliderLeft() {
      let index;
      if (this.selectedTemplateCarousel.index == 0) {
        index = this.selectedTemplateStyle.templateCarousel.length - 1;
        this.setTemplateCarousel(
          this.selectedTemplateStyle.templateCarousel[index],
          index
        );
      } else {
        index = this.selectedTemplateCarousel.index - 1;
        this.setTemplateCarousel(
          this.selectedTemplateStyle.templateCarousel[index],
          index
        );
      }
    },
    setTemplateCarousel(templateCarousel, index, autoDownload = false) {
      this.loadingCanvas = true;
      this.selectedTemplateCarousel = templateCarousel;
      this.selectedTemplateCarousel.index = index;
      this.setCanvasImage(templateCarousel.image, autoDownload);
    },
    setCanvasImage(image, autoDownload = false) {
      new fabric.Image.fromURL(
        `${image}`, // new Date().getTime()
        (img) => {
          img.scaleToWidth(this.canvas.width);
          img.scaleToHeight(this.canvas.height);
          this.canvas.setBackgroundImage(img, () => {
            this.canvas.requestRenderAll();
            this.loadingCanvas = false;
            this.loadingItemCarousel = false;
          });
          if (autoDownload) {
            setTimeout(() => {
              this.downloadFile();
            }, 100);
          }
        },
        { crossOrigin: "anonymous" }
      );
    },
    changeStyle() {
      this.loadingItemCarousel = true;
      this.loadingCanvas = true;
      let styleObj = this.template.templateStyle.find(
        (obj) => obj.style.id === this.selectedStyle
      );
      if (styleObj) {
        this.selectedTemplateStyle = styleObj;

        if (this.template.format == "images") {
          this.setCanvasImage(styleObj.image);
        } else if (this.template.format == "carousel") {
          this.setTemplateCarousel(styleObj.templateCarousel[0], 0);
        } else {
          this.selectedVideo = styleObj.video;
        }
      }
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    downloadFile() {
      this.downloading = true;
      if (platform.isMobile()) {
        this.downloadFileMobile();
      } else {
        this.downloadFileWeb();
      }
    },
    downloadFileMobile() {
      var isVideo =
        this.template.format != "images" && this.template.format != "carousel";
      var fileExtension = isVideo ? "mp4" : "png";
      var contentType = isVideo ? "video/mp4" : "image/png";

      if (isVideo) {
        var vm = this;
        let templateStyle = this.template.templateStyle.filter(
          (i) => i.video == this.selectedVideo
        )[0];
        let fileName = `${this.template.title}.mp4`;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", templateStyle.video, true);
        xhr.responseType = "blob";
        var comp = this;
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
          }
        };
        xhr.onload = function () {
          vm.downloadBlob(this.response, fileExtension, contentType);
        };
        xhr.send();
      } else {
        let resizeFactor = 1080 / this.canvas.width;
        var filePath = this.canvas.toDataURL({
          format: contentType,
          multiplier: resizeFactor,
        });
        var blob = this.dataURItoBlob(filePath);
        this.downloadBlob(blob, fileExtension, contentType);
      }
    },
    downloadBlob(blob, fileExtension, contentType) {
      var vm = this;
      var fileName = `${this.template.id}.${fileExtension}`;
      var systemUrl = platform.isIos()
        ? cordova.file.documentsDirectory
        : cordova.file.externalRootDirectory + "/Download";

      window.resolveLocalFileSystemURL(systemUrl, function (dir) {
        dir.getFile(fileName, { create: true }, function (file) {
          file.createWriter(function (fileWriter) {
            fileWriter.onwriteend = function () {
              cordova.plugins.fileOpener2.open(file.nativeURL, contentType, {
                error: function (e) {
                  console.log(
                    "Error status: " +
                      e.status +
                      " - Error message: " +
                      e.message
                  );
                },
                success: function () {
                  vm.downloading = false;
                  console.log("file opened successfully");
                },
              });
            };
            fileWriter.seek(fileWriter.length);
            fileWriter.write(blob);
          });
        });
      });
    },
    downloadFileWeb() {
      if (
        this.template.format == "images" ||
        this.template.format == "carousel"
      ) {
        let resizeFactor = 1080 / this.canvas.width;
        var filePath = this.canvas.toDataURL({
          format: "image/jpg",
          multiplier: resizeFactor,
        });
        let link = document.createElement("a");
        link.href = filePath;
        link.download = "Do It Social - Imagem.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$swal({
          type: "success",
          toast: true,
          title: (this.warningMessage =
            this.$translate.locale["Imagem baixada!"]),
          position: "top",
          showConfirmButton: false,
          timer: 4000,
        });
        this.downloading = false;
      } else {
        let templateStyle = this.template.templateStyle.filter(
          (i) => i.video == this.selectedVideo
        )[0];
        let fileName = `${this.template.title}.mp4`;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", templateStyle.video, true);
        xhr.responseType = "blob";
        var comp = this;
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            comp.downloading = false;
            this.$swal({
              type: "success",
              toast: true,
              title: (this.warningMessage =
                this.$translate.locale["Vídeo baixado!"]),
              position: "top",
              showConfirmButton: false,
              timer: 4000,
            });
          }
        };
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          var tag = document.createElement("a");
          tag.href = imageUrl;
          tag.download = fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        };
        xhr.send();
      }
    },
    copyDescription() {
      const el = document.createElement("textarea");
      el.value = this.template.description;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$swal({
        type: "success",
        toast: true,
        title: (this.warningMessage = this.$translate.locale["Texto copiado!"]),
        position: "top",
        showConfirmButton: false,
        timer: 4000,
      });
    },
    validateUserInfo() {
      let receipt = "";
      if (
        platform.isIos() &&
        authentication.getUser().info.userType === "Apple"
      ) {
        receipt = state?.subscription?.transaction?.appStoreReceipt || "";
      }
      http
        .post("User/Subscription", { receipt }, authentication.getHeaders())
        .then(() => {
          if (
            !authentication.getUser().info?.professionalName ||
            !authentication.getUser().info?.image
          ) {
            this.userInfoOk = false;
          }
          this.loadTemplate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadTemplate() {
      http
        .get(`template/${this.$route.params.id}`, authentication.getHeaders())
        .then((response) => {
          this.template = response.data;
          if (
            this.userInfoOk &&
            (this.template.format == "images" ||
              this.template.format == "carousel")
          )
            this.loadCanvas();

          if (localStorage.selectedStyle) {
            this.selectedStyle = JSON.parse(localStorage.selectedStyle)?.id;

            this.selectedVideo = this.template.templateStyle.filter(
              (ts) => ts.styleId == this.selectedStyle
            )[0].video;
          } else {
            this.selectedStyle = this.template.templateStyle[0].style.id;
            this.selectedVideo = this.template.templateStyle[0].video;
          }
        })
        .catch((err) => {
          this.warningLoading = true;
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCanvas() {
      const canvasHolder = document.querySelector("#canvasHolder");
      let htmlCanvas = document.querySelector("#templateCanvas");

      this.canvas = new fabric.Canvas("templateCanvas");

      fabric.Object.NUM_FRACTION_DIGITS = 17;

      let height =
        this.template.type === "stories"
          ? canvasHolder.clientWidth * 1.77777777777
          : canvasHolder.clientWidth;

      if (canvasHolder.clientWidth < 1000) {
        htmlCanvas.width = canvasHolder.clientWidth;
        htmlCanvas.height = height;
        this.canvas.setWidth(canvasHolder.clientWidth);
        this.canvas.setHeight(height);
      } else {
        htmlCanvas.width = 1000;
        htmlCanvas.height = height;
        this.canvas.setWidth(1000);
        this.canvas.setHeight(height);
      }

      let objCanvas = JSON.parse(this.template.canvas);
      objCanvas.objects[0].objects[0].src = "";
      objCanvas.objects[0].objects[2].type = "";
      objCanvas.objects[0].objects[2].src = "";

      objCanvas = JSON.stringify(objCanvas);

      const selectedStyleId = JSON.parse(localStorage.selectedStyle)?.id;
      const currentStyle = this.template.templateStyle.find(
        (obj) => obj.styleId == selectedStyleId
      );
      this.selectedTemplateStyle = currentStyle;

      this.canvas.loadFromJSON(objCanvas, () => {
        if (
          this.template.format === "images" ||
          this.template.format === "videos"
        ) {
          this.setCanvasImage(currentStyle.image);
        } else if (this.template.format === "carousel") {
          this.setTemplateCarousel(currentStyle.templateCarousel[0], 0);
        }
        for (let i = 0; i < this.canvas.getObjects().length; i++) {
          let obj = this.canvas.getObjects()[i];
          obj.hasControls = false;
          obj.selectable = false;
          this.scaleCanvasObjects(obj);
          obj.setCoords();
          if (obj.type === "group") {
            obj.getObjects().map((o) => {
              this.scaleCanvasObjects(o);
            });
          }
        }

        this.canvas.set({ selection: false });

        this.canvas.on("mouse:over", (event) => {
          if (event.target)
            event.target.hoverCursor = this.canvas.defaultCursor;
        });
        // this.showCanvas = true;
        this.addSignature();
      });
    },
    addSignature() {
      var vm = this;
      let group, baseImage, circle, name, career, instagram, gender;

      group = this.canvas.getObjects()[0];
      gender = authentication.getUser().info.gender;
      baseImage = group.getObjects()[0];
      circle = group.getObjects()[1];
      name = group.getObjects()[2];
      career = group.getObjects()[3];
      instagram = group.getObjects()[3];

      group.remove(baseImage);

      new fabric.Image.fromURL(
        require("@/assets/images/signature-base.png"),
        (img) => {
          img.set({
            width: baseImage.width,
            height: baseImage.height,
            scaleX: baseImage.scaleX,
            scaleY: baseImage.scaleY,
            top: baseImage.top - 1,
            left: baseImage.left - 1,
          });
          group.add(img);
          img.sendToBack();
          this.canvas.requestRenderAll();
        },
        { crossOrigin: "anonymous" }
      );

      name.text = authentication.getUser().info.professionalName;
      name.fontFamily = "maryKayScript";
      career.text = authentication.getUser().info.career;
      instagram.text = authentication.getUser().info.instagram;

      new fabric.Image.fromURL(
        `${authentication.getUser().info.image}?_`,
        (img) => {
          img.set({
            top: circle.top,
            left: circle.left,
            selectable: false,
          });
          img.scaleToWidth(circle.getScaledWidth());
          if (this.canvas.grayscale) {
            img.filters.push(new fabric.Image.filters.Grayscale());
            img.applyFilters();
          }
          group.add(img);
          circle = new fabric.Circle({
            top: -img.height / 2,
            left: -img.width / 2,
            radius: img.width / 2,
            fill: "#fff",
            selectable: false,
          });
          img.set({
            clipPath: circle,
          });
          vm.canvas.requestRenderAll();

          this.showCanvas = true;
        },
        { crossOrigin: "anonymous" }
      );
    },
    proportionalSize(originalSize) {
      const adminCanvasSize = 420;
      const percent = (originalSize * 100) / adminCanvasSize;
      return this.canvas.getWidth() * (percent / 100);
    },
    scaleCanvasObjects(obj) {
      obj.left = this.proportionalSize(obj.left);
      obj.top = this.proportionalSize(obj.top);

      if (obj.width && obj.type != "image") {
        obj.width = this.proportionalSize(obj.width);
      }
      if (obj.height && obj.type != "image") {
        obj.height = this.proportionalSize(obj.height);
      }
      if (obj.radius) {
        obj.setRadius(this.proportionalSize(obj.radius) * 1.0001);
      }
      if (obj.fontSize) {
        obj.fontSize = this.proportionalSize(obj.fontSize);
      }
      if (obj.type === "image") {
        const adminCanvasSize = 420;
        const ratio = adminCanvasSize / this.canvas.getWidth();
        // console.log("canvas size: " + this.canvas.getWidth());
        // console.log("ratio: " + ratio);
        obj.scaleToWidth(obj.getScaledWidth() / ratio);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
}

.grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 0.375fr 0.625fr;
  margin: 25px 0 0 0;
  gap: 0 3.5rem;
  @media screen and (max-width: 1139px) {
    display: block;
  }
}

.template {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 3px;
  @media screen and (max-width: 720px) {
    margin-top: 55px;
  }
}

.canvas-holder {
  background-color: var(--secondary);
  .active {
    opacity: 0.3;
  }
}

.canvas-icons {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  .canvas-loading {
    position: relative;
    margin: 0 auto;
    height: 25px;
    width: 25px;
  }
  .control {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #333;
    display: grid;
    place-items: center;
    left: -20px;
    &.next {
      left: initial;
      right: -20px;
    }
    svg {
      font-size: 1.4em;
      color: #fff;
    }
  }
}

.hidden-canvas {
  visibility: hidden;
}

.info-holder {
  position: relative;
  h1 {
    font-size: 2em;
  }
  p {
    margin-top: 10px;
    font-size: 1.15em;
  }
  .btns-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    .btn.border {
      border-color: var(--secondary);
      span,
      svg {
        color: var(--secondary);
      }
    }
  }
}

.textarea-description {
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 110px;
  padding: 12px;
  margin: 15px 0 0 0;
  border-radius: 4px;
  resize: none;
  animation-delay: 300ms;
}

.btn:not(.border) {
  background: var(--secondary);
  span,
  svg {
    color: var(--primary);
  }
}

.btn.border.info {
  border-color: var(--secondary);
  span {
    color: var(--secondary);
  }
}

h3 {
  font-size: 1.4em;
  margin-bottom: 15px;
}

.styles {
  position: relative;
  display: block;
  max-width: 360px;
  height: 34px;
  margin: 0 0 10px auto;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: 720px) {
    max-width: initial;
    width: 100%;
    margin: 15px auto 0 auto;
  }
}

.carousel-text-help {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 25px !important;
  color: #888;
  font-style: italic;
}

.grid-images {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 0.5fr));
  gap: 40px;
  justify-content: flex-start;

  .carousel-item {
    position: relative;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 0.8em;
    cursor: pointer;
    &.stories {
      padding-bottom: 177.777%;
    }
    .download {
      position: absolute;
      width: 75px;
      height: 30px;
      padding: 6px 12px;
      bottom: -38px;
      right: 8px;
      border-radius: 3px;
      display: grid;
      place-items: center;
      background-color: var(--primary);
      background: linear-gradient(176deg, #ebd7b3, #c89d87);
      color: #000;
      cursor: pointer;
      font-size: 1.15em;
      font-weight: bold;
      z-index: 999;
    }
  }
}

#templateCanvas {
  box-shadow: 3px 2px 13px -3px rgba(0, 0, 0, 0.2);
}
</style>
